import React from "react";
import { Link } from "react-router-dom";
import birdMascot from "../assets/logo-500px-transparent.png";
import saucerSwapLogo from "../assets/Larry.svg";
import { motion } from "framer-motion";
import { FaGithub, FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
const Introducing: React.FC = () => {
  return (
    <header
      id="introducing"
      className="relative min-h-screen flex items-center justify-center overflow-hidden"
    >
      {/* Animated Background */}
      <div className="absolute inset-0 bg-[#FDF8F3]">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 2 }}
          className="absolute inset-0"
          style={{
            backgroundImage:
              "radial-gradient(circle at 50% 50%, #FF6B6B 1px, transparent 1px)",
            backgroundSize: "50px 50px",
          }}
        />
      </div>

      {/* Floating Elements */}
      <motion.div
        animate={{
          y: [0, -20, 0],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="absolute left-10 top-32 w-32 h-32 bg-gradient-to-r from-orange-400/30 to-yellow-400/30 rounded-full blur-xl"
      />
      <motion.div
        animate={{
          y: [0, 20, 0],
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="absolute right-10 bottom-32 w-32 h-32 bg-gradient-to-r from-teal-400/30 to-blue-400/30 rounded-full blur-xl"
      />

      {/* Main Content */}
      <div className="relative z-10 max-w-5xl mx-auto px-6 pb-6 pt-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          {/* Logo Section */}
          <div className="relative inline-block">
            <motion.img
              src={birdMascot}
              alt="iAssets Bird Mascot"
              className="w-32 h-32 mx-auto mb-1"
              animate={{
                y: [0, -3, 0],
                rotate: [0, 2, 0],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.div
              className="absolute -bottom-4 left-1/2 -translate-x-1/2 w-16 h-4 bg-black/10 rounded-full blur-sm"
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.3, 0.2, 0.3],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
          </div>

          {/* Title and Version */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <h1 className="text-7xl font-extrabold text-[#2D1B2D] mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-orange-500">
              iAssets
            </h1>
          </motion.div>

          {/* Description */}
          <motion.p
            className="text-2xl text-gray-800 mb-12 leading-relaxed max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            Building
            <span className="text-sky-900 font-semibold"> iBird</span> on{" "}
            <span className="text-sky-600 font-semibold">Hedera</span>
            <br />
            <span className="text-teal-900 font-semibold">A</span>{" "}
            <span className="text-orange-600 font-semibold">Web3</span>,{" "}
            <span className="text-teal-700 font-semibold">decentralized</span>,{" "}
            <span className="text-purple-700 font-semibold">
              community-driven
            </span>
            , and{" "}
            <span className="text-blue-700 font-semibold">open-source</span>{" "}
            social media platform where your voice is free!
          </motion.p>

          {/* CTA Buttons */}
          <motion.div
            className="flex flex-wrap justify-center gap-4 mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7 }}
          >
            <Link
              to="/convert"
              className="group inline-flex items-center px-8 py-4 text-lg font-medium text-white bg-gradient-to-r from-orange-500 to-pink-500 rounded-full hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-orange-500/25"
            >
              Update Tokens
              <motion.span
                className="ml-2"
                animate={{ x: [0, 5, 0] }}
                transition={{ duration: 1.5, repeat: Infinity }}
              >
                →
              </motion.span>
            </Link>
            <Link
              target="_blank"
              to="https://ibird.io"
              className="inline-flex items-center px-8 py-4 text-lg font-medium text-gray-700 bg-white rounded-full hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-gray-300/50 border-2 border-gray-100"
            >
              Join iBird
            </Link>
          </motion.div>

          {/* New Swap Buttons Section */}
          <motion.div
            className="flex flex-col items-center gap-3 mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
          >
            <a
              href="https://www.saucerswap.finance/swap/HBAR/0.0.1991880"
              target="_blank"
              rel="noopener noreferrer"
              className=" transition-all duration-300  hover:scale-105 flex items-center"
            >
              <img
                src={saucerSwapLogo}
                alt="SaucerSwap"
                className="h-12 w-auto"
              />
            </a>
            <div className="flex flex-wrap justify-center gap-3">
              <a
                href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x12Da2f2761038486271C99DA7e0FB4413e2B5E38"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 text-sm font-medium text-purple-700 bg-purple-50 rounded-full hover:bg-purple-100 transition-all duration-300 border border-purple-200 hover:scale-105"
              >
                NBM (BSC)
              </a>
              <a
                href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x6b471d5ab9f3d92a600e7d49a0b135bf6d4c6a5b"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 text-sm font-medium text-blue-700 bg-blue-50 rounded-full hover:bg-blue-100 transition-all duration-300 border border-blue-200 hover:scale-105"
              >
                ASSET (BSC)
              </a>
            </div>
          </motion.div>

          {/* Social Links */}
          <motion.div
            className="flex justify-center gap-6 mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.9 }}
          >
            {[
              {
                icon: FaGithub,
                link: "https://github.com/iassetsorg/Project-Harriet",
              },
              { icon: FaDiscord, link: "http://discord.gg/xM7SkkTEAG" },
              { icon: FaXTwitter, link: "https://x.com/iAssetsOrg" },
            ].map((social, index) => (
              <a
                key={index}
                href={social.link}
                className="text-gray-600 hover:text-gray-900 transition-colors duration-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                <social.icon className="w-6 h-6" />
              </a>
            ))}
          </motion.div>
        </motion.div>
      </div>
    </header>
  );
};

export default Introducing;
